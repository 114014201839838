import { bindable, inject }                from 'aurelia-framework';
import { I18N }                            from 'aurelia-i18n';
import { PLATFORM }                        from 'aurelia-pal';
import { BaseListViewModel }               from 'base-list-view-model';
import { SelectedBulletinFilesRepository } from 'modules/aggregates/selected-bulletins/services/files-repository';
import { FilterFormSchema }                from 'modules/management/processing-errors/filter-form-schema';
import { AppContainer }                    from 'resources/services/app-container';
import { Downloader }                      from 'resources/services/downloader';
import { ProcessingErrorsRepository }      from './services/repository';

@inject(AppContainer, ProcessingErrorsRepository, I18N, Downloader, FilterFormSchema)
export class ListProcessingErrors extends BaseListViewModel {

    listingId = 'management-processing-errors-listing';

    @bindable headerTitle    = 'listing.management.processing-errors';
    @bindable newRecordRoute = null;
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.processing-errors.access',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        // TODO
        //this.filterSchema = this.filterFormSchema.schema(this);
        //this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            null,
            edit:            null,
            destroy:         false,
            selectable:      false,
            destroySelected: false,
            options:         [],
            sorting:         {
                column:    11,
                direction: 'desc',
            },
            columns:         [
                {
                    data:  'number',
                    name:  'bulletin_processing_errors.number',
                    title: 'form.field.number',
                },
                {
                    data:  'concession_description',
                    name:  'concession_translations.description',
                    title: 'form.field.concession',
                },
                {
                    data:  'highway_designation',
                    name:  'highway_translations.designation',
                    title: 'form.field.highway',
                },
                {
                    data:  'intervention_type_designation',
                    name:  'intervention_type_translations.designation',
                    title: 'form.field.intervention-type',
                },
                {
                    data:  'lot_intervention_designation',
                    name:  'lot_intervention_translations.designation',
                    title: 'form.field.lot-intervention',
                },
                {
                    data:   'entity_name',
                    name:   'entity_translations.name',
                    title:  'form.field.entity',
                    hidden: true,
                },
                {
                    data:   'team_name',
                    name:   'team_translations.name',
                    title:  'form.field.team',
                    hidden: true,
                },
                {
                    data:  'entity_team',
                    name:  'team_translations.name',
                    title: 'form.field.entity',
                },
                {
                    data:  'message',
                    name:  'bulletin_processing_message_translations.message',
                    title: 'form.field.error-message',
                },
                {
                    data:       'test_number',
                    name:       'test_number',
                    title:      'form.field.test-number/sample-number',
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:       'test_sector',
                    name:       'test_sector',
                    title:      'form.field.type',
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:            'created_at',
                    name:            'bulletin_processing_errors.created_at',
                    title:           'form.field.date',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:            'file',
                    name:            'file',
                    title:           'form.field.file',
                    type:            'custom-cell',
                    viewModel:       PLATFORM.moduleName('modules/core/custom-listing-cells/file/index'),
                    filesRepository: SelectedBulletinFilesRepository,
                    orderable:       false,
                    searchable:      false,
                },
            ],
        };
    }

}
