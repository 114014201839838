import { inject }                       from 'aurelia-framework';
import { ReportHeadersFilesRepository } from 'modules/management/report-headers/services/files-repository';
import { Downloader }                   from 'resources/services/downloader';

@inject(ReportHeadersFilesRepository, Downloader)
export class ReportHeadersFileCustomListingCell {
    /**
     * Constructor.
     *
     * @param filesRepository
     * @param downloader
     */
    constructor(filesRepository, downloader) {
        this.filesRepository = filesRepository;
        this.downloader      = downloader;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.column = model.column;
        this.row    = model.row;
    }

    /**
     * Downloads the file
     */
    downloadFile(file) {
        this.filesRepository
            .download(file.id)
            .then(result => {
                if (result) {
                    // remove the file extension from the file name
                    const fileName = file.display_name.replace(/\.[^/.]+$/, '');
                    this.downloader.download(result, fileName, file.extension);
                }
            });
    }
}
