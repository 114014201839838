import { inject }                       from 'aurelia-framework';
import { PLATFORM }                     from 'aurelia-pal';
import { BaseFormViewModel }            from 'base-form-view-model';
import { FormSchema }                   from 'modules/management/report-headers/form-schema';
import { ReportHeadersRepository }      from 'modules/management/report-headers/services/repository';
import { ReportCategoriesRepository }   from 'modules/management/report-headers/services/categories-repository';
import { ReportHeadersFilesRepository } from 'modules/management/report-headers/services/files-repository';
import { AppContainer }                 from 'resources/services/app-container';
import { Downloader }                   from 'resources/services/downloader';

@inject(AppContainer, ReportHeadersRepository, ReportCategoriesRepository, ReportHeadersFilesRepository, FormSchema, Downloader)
export class ViewReportHeader extends BaseFormViewModel {
    headerTitle = 'form.title.view-record';
    formId      = 'management.report-headers.view-form';
    readonly    = true;

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param categoriesRepository
     * @param filesRepository
     * @param formSchema
     * @param downloader
     */
    constructor(appContainer, repository, categoriesRepository, filesRepository, formSchema, downloader) {
        super(appContainer);

        this.repository           = repository;
        this.categoriesRepository = categoriesRepository;
        this.filesRepository      = filesRepository;
        this.formSchema           = formSchema;
        this.downloader           = downloader;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/report-headers/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate(params) {

        this.reportCategory = params.category;
        this.repository.setCategory(this.reportCategory.id);

        return super.can([
            `management.report-headers.manage`,
            `management.report-headers.view`,
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);
        this.schema       = this.formSchema.schema(this, this.readonly);
        this.globalSchema = this.formSchema.globalSchema(this, this.readonly);
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData({ id }) {
        const response = await this.repository.find(id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }

    /**
     * Downloads the file
     *
     * @param file
     */
    downloadFile(file) {
        this.filesRepository.download(file.id)
            .then(result => {
                if (result) {
                    // remove the file extension from the file name
                    const fileName = file.display_name.replace(/\.[^/.]+$/, '');
                    this.downloader.download(result, fileName, file.extension);
                }
            });
    }
}
